import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_APP_VERSION } from "../../redux/commonSlice";
import "./AppVersionBadge.css";
import { useConfig } from "../../../customHooks/useConfig";

const AppVersionBadge = () => {
  const { appVersion } = useSelector((state) => state.commonReducer);
  let { jsonConfig } = useConfig();
  const dispatch = useDispatch();
  useEffect(() => {
    setAppVersion();
  }, []);

  const setAppVersion = async () => {
    dispatch(SET_APP_VERSION(jsonConfig?.APP_VERSION));
  };
  return <p className="app_version">{appVersion}</p>;
};
export default AppVersionBadge;
