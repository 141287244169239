export const KEY_REF = {
  voiceId: "ampshortName",
  voiceUUID: "ampvoiceUUID",
  voiceProvider: "ampVoiceProvider",
  voiceType: "ampvoiceType",
  artistName: "ampDisplayName",
  age: "ampvoiceagerange",
  gender: "ampvoiceGender",
  picture: "ampvoicePhoto",
  audioSample: "ampvoicesampleMp3",
  language: "ampvoicelocale",
  languageId: "ampvoicelocaleID",
  accent: "ampvoiceaccent",
  accentId: "ampvoiceaccentID",
  tags: "amptagsArray",
  //speakingStyles: "ampbestForArray",
  speakingStyles: "ampStylesArray",
  pronunciationUUID: "pronunciationUUID",
  isOnBrand: "isOnBrand",
  voiceType: "ampvoiceType",
};
