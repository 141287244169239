import _ from "lodash";

let wppThemeDataL = {};
let wppThemeData = {};

const getWPPThemeJson = (osContextTheme) => {
  console.log("osContextTheme from theme CS :::::", osContextTheme);
  wppThemeData = osContextTheme;
  wppThemeDataL = osContextTheme;
  // console.log("wppThemeDataL::getWPPThemeJson theme data", wppThemeDataL);
  // console.log(
  //   "getwppthemejson:: primary color cs",
  //   _.get(wppThemeData, "color.brand.default")
  // );

  let themeObj = {
    "--color-bg": _.get(wppThemeData, "surface.bgColor"),
    "--color-error": wppThemeDataL?.color?.dataviz?.seq?.negative[400],
    "--color-success": wppThemeDataL?.color?.dataviz?.seq?.positive[400],
    "--color-warning": wppThemeDataL?.color?.dataviz?.seq?.warning[400],
    "--color-wave-progress": "#A2D2A2",
    "--color-wave-bg": wppThemeDataL?.color?.grey[700],
    "--color-primary": wppThemeDataL?.color?.primary[500],
    "--color-primary-shade1": wppThemeDataL?.color?.primary[600],
    "--color-primary-shade2": wppThemeDataL?.color?.primary[400],
    "--color-primary-shade3": wppThemeDataL?.color?.primary[300],
    "--color-primary-shade4": wppThemeDataL?.color?.primary[200],
    "--color-white": "#000000",
    "--color-black": "#000000",
    "--color-secondary": wppThemeDataL?.color?.grey[300],
    "--color-secondary-shade1": "#1E2023",
    "--color-secondary-shade2": "#54565A",
    "--color-secondary-shade3": "#636569",
    "--color-secondary-shade4": "#D0CFCD",
    "--color-secondary-shade5": "#F3F3F3",
    "--color-secondary-shade6": "#797979",
    "--color-secondary-shade7": "#1B1D1F",
    "--color-secondary-shade8": "#757575",
    "--color-secondary-shade9": "#333538",
    "--color-secondary-shade10": "#121212",
    "--color-secondary-shade11": "#797979",
    "--color-secondary-shade12": "#4C4C4C",
    "--color-icon": "#000",
    "--color-icon-active": "#000",
    "--color-icon-inactive": "#757575",
    "--color-lightgray": "#666666",
    "--browse-background-color": "#000000",
    "--color-loader": wppThemeDataL?.color?.grey[900],
    "--font-primary": wppThemeDataL?.font?.family,
    "--font-primary-light": wppThemeDataL?.font?.family,
    "--font-primary-medium": wppThemeDataL?.font?.family,
    "--font-primary-bold": wppThemeDataL?.font?.family,
    "--color-donutchart-primary": "#cf6175",
    "--color-donutchart-secondary": "#af5c86",
    "--color-donutchart-tertiary": "#865b8c",
    "--color-donutchart-quaternary": "#5e5883",
    "--color-donutchart-quinary": "#3e5270",
    "--color-donutchart-senary": "#2f4858",

    "--color-voice1": "#D9F9D9",
    "--color-voice2": "#FFB6C5",
    "--color-voice3": "#FDDE7C",
    "--color-voice4": "#4D7CFE",
    "--color-voice5": "#F1775C",
    "--color-voice6": "#7DBAE7",
    "--color-voice7": "#A6D17A",
    "--color-voice8": "#FFB864",
    "--color-voice9": "#DA7B7B",
    "--color-voice10": "#C89EFF",
    "--color-voice11": "#E0FABF",
    "--color-voice12": "#9DF6E6",
    "--color-voice13": "#D6BCC0",
    "--color-voice14": "#9F4452",
    "--color-voice15": "#6775BC",
    "--color-voice16": "#BEC9FF",
    "--color-voice17": "#4D9588",
    "--color-voice18": "#E2C1A4",
    "--color-voice19": "#6C1E17",
    "--color-voice20": "#C37D14",
    "--color-voice21": "#A07E7E",
    "--color-voice22": "#FFFFFF",
  };
  // console.log("CS themeObj:::::::", themeObj);
  return themeObj;
};

export default getWPPThemeJson;
